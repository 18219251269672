* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #ffcc00;
  font-size: 12px;
  border: none;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-button:hover {
  background-color: #c09a05 !important;
}
